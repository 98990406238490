import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import emailjs from "@emailjs/browser";
import Notiflix from "notiflix";

const DemoProduct = (props) => {
	useDocTitle("SyncroBio Consulting");

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [demoProducts, setDemoProducts] = useState([]);
	const [errors, setErrors] = useState([]);

	const handleChange = (e) => {
		const value = e.target.value;
		const checked = e.target.checked;
		errors.products = [];
		if (checked) {
			setDemoProducts([...demoProducts, value]);
		} else {
			setDemoProducts(demoProducts.filter((e) => e !== value));
		}
	};
	const clearErrors = () => {
		setErrors([]);
	};

	const clearInput = () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPhone("");
		setMessage("");
	};

	function sendEmail(e) {
		e.preventDefault();

		e.preventDefault();
		const firstName = e.target.first_name.value;
		const lastName = e.target.last_name.value;
		const email = e.target.email.value;
		const phone = e.target.phone_number.value;
		const message = e.target.message.value;

		if (
			firstName !== null &&
			firstName !== undefined &&
			firstName.trim() !== "" &&
			lastName !== null &&
			lastName !== undefined &&
			lastName.trim() !== "" &&
			email !== null &&
			email !== undefined &&
			email.trim() !== "" &&
			phone !== null &&
			phone !== undefined &&
			phone.trim() !== "" &&
			message !== null &&
			message !== undefined &&
			message.trim() !== ""
		) {
			const text = `

			!!! This message is from SyncroBio Website !!!
			products: ${demoProducts}
			firstname: ${firstName}
			lastname: ${lastName}
			email: ${email}
			phone: ${phone}
			message: ${message}`;

			document.getElementById("submitBtn").disabled = true;
			document.getElementById("submitBtn").innerHTML = "Loading...";

			emailjs.send(
				"bathyal_district",
				"bathyal_district_shop",
				{ email: text },
				"iFI9yH38gPvoh3sgX"
			);

			document.getElementById("submitBtn").disabled = false;
			document.getElementById("submitBtn").innerHTML = "send message";
			clearInput();
			Notiflix.Report.success(
				"Thank you for your message",
				"We'll get back to you soon."
			);
		} else {
			document.getElementById("submitBtn").disabled = false;
			document.getElementById("submitBtn").innerHTML = "send message";
			Notiflix.Report.failure(
				"An error occurred",
				"It seems that you left some fields empty. Please provide all the required fields and we will get back to you soon."
			);
		}
	}

	return (
		<>
			<div>
				<NavBar />
			</div>
			<div
				id="demo"
				className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
				<div
					className="container mx-auto my-8 px-4 lg:px-20"
					data-aos="zoom-in">
					<form
						onSubmit={sendEmail}
						id="demoProductForm">
						<div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
							<div className="flex">
								<h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">
									Demo our products
								</h1>
							</div>
							<div className="flex items-center my-4">
								<input
									id="checkbox-1"
									aria-describedby="checkbox-1"
									type="checkbox"
									className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
									value="Custom Health IT Solutions"
									onChange={handleChange}
								/>
								<label
									htmlFor="checkbox-1"
									className="ml-3 text-lg font-medium text-gray-900">
									Custom Health IT Solutions
								</label>
							</div>
							<div className="flex items-center my-4">
								<input
									id="checkbox-1"
									aria-describedby="checkbox-1"
									type="checkbox"
									className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
									value="Remote Patient Monitoring"
									onChange={handleChange}
								/>
								<label
									htmlFor="checkbox-1"
									className="ml-3 text-lg font-medium text-gray-900">
									Remote Patient Monitoring
								</label>
							</div>
							<div className="flex items-center my-4">
								<input
									id="checkbox-1"
									aria-describedby="checkbox-1"
									type="checkbox"
									className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
									value="Diagnostics and Analytics"
									onChange={handleChange}
								/>
								<label
									htmlFor="checkbox-1"
									className="ml-3 text-lg font-medium text-gray-900">
									Diagnostics and Analytics
								</label>
							</div>
							<div className="flex items-center my-4">
								<input
									id="checkbox-1"
									aria-describedby="checkbox-1"
									type="checkbox"
									className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"
									value="Data Security and Compliance"
									onChange={handleChange}
								/>
								<label
									htmlFor="checkbox-1"
									className="ml-3 text-lg font-medium text-gray-900">
									Data Security and Compliance
								</label>
							</div>
							{errors && (
								<p className="text-red-500 text-sm">{errors.products}</p>
							)}

							<div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
								<div>
									<input
										name="first_name"
										className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										type="text"
										placeholder="First Name*"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
										onKeyUp={clearErrors}
									/>
									{errors && (
										<p className="text-red-500 text-sm">{errors.first_name}</p>
									)}
								</div>

								<div>
									<input
										name="last_name"
										className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										type="text"
										placeholder="Last Name*"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
										onKeyUp={clearErrors}
									/>
									{errors && (
										<p className="text-red-500 text-sm">{errors.last_name}</p>
									)}
								</div>

								<div>
									<input
										name="email"
										className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										type="email"
										placeholder="Email*"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										onKeyUp={clearErrors}
									/>
									{errors && (
										<p className="text-red-500 text-sm">{errors.email}</p>
									)}
								</div>

								<div>
									<input
										name="phone_number"
										className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
										type="number"
										placeholder="Phone*"
										value={phone}
										onChange={(e) => setPhone(e.target.value)}
										onKeyUp={clearErrors}
									/>
									{errors && (
										<p className="text-red-500 text-sm">
											{errors.phone_number}
										</p>
									)}
								</div>
							</div>
							<div className="my-4">
								<textarea
									name="message"
									placeholder="Message*"
									className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									onKeyUp={clearErrors}></textarea>
								{errors && (
									<p className="text-red-500 text-sm">{errors.message}</p>
								)}
							</div>
							<div className="my-2 w-1/2 lg:w-2/4">
								<button
									type="submit"
									id="submitBtn"
									className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
									Send Message
								</button>
							</div>
						</div>
					</form>
					<div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-blue-900 rounded-2xl">
						<div className="flex flex-col text-white">
							<div className="flex my-4 w-2/3 lg:w-3/4">
								<div className="flex flex-col">
									<i className="fas fa-map-marker-alt pt-2 pr-2" />
								</div>
								<div className="flex flex-col">
									<h2 className="text-2xl">Office Address</h2>
									<p className="text-gray-400">
										Kapás u. 11, Budapest, 1027 Hungary
									</p>
								</div>
							</div>

							<div className="flex my-4 w-2/3 lg:w-1/2">
								<div className="flex flex-col">
									<i className="fas fa-phone-alt pt-2 pr-2" />
								</div>

								<div className="flex flex-col">
									<div className="mt-5">
										<h2 className="text-2xl">Send an Email</h2>
										<p className="text-gray-400">info@syncrobio.com</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default DemoProduct;

import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
	return (
		<footer>
			<div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
				{/* Top area: Blocks */}
				<div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
					{/* 1st block */}
					<div className="col-span-12 lg:col-span-4">
						<div className="box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
							<h3 className="font-bold text-4xl mb-1">SyncroBio</h3>
							<h3 className="text-3xl mb-4">Consulting</h3>
							<div className="text-md font-medium text-gray-600">
								<h5>Kapás u. 11,</h5>
								<p>Budapest,</p>
								<p>1027 Hungary</p>
							</div>
						</div>
					</div>

					{/* 2nd block */}
					<div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
						<h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
						<ul className="text-md">
							<li className="mb-2">
								<HashLink
									to="#about"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									About
								</HashLink>
							</li>
							<li className="mb-2">
								<HashLink
									to="#services"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Services
								</HashLink>
							</li>
							<li className="mb-2">
								<HashLink
									to="#"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Contact
								</HashLink>
							</li>
						</ul>
					</div>

					{/* 3rd block */}
					<div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
						<h6 className="text-[#013289] text-xl font-bold mb-4">
							OUR SERVICES
						</h6>
						<ul className="text-md">
							<li className="mb-2">
								<Link
									to="#"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Custom Health IT Solutions
								</Link>
							</li>
							<li className="mb-2">
								<Link
									to="#"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Remote Patient Monitoring
								</Link>
							</li>
							<li className="mb-2">
								<Link
									to="#"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Diagnostics and Analytics
								</Link>
							</li>
							<li className="mb-2">
								<Link
									to="#"
									className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
									Data Security and Compliance
								</Link>
							</li>
						</ul>
					</div>

					{/* 4th block */}
					<div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
						<div className="text-xl mb-6">Social Media Links.</div>

						<div className="text-md font-medium mb-6">
							Follow us on social media.
						</div>
						<div className="mx-auto text-center mt-2">
							<ul className="flex justify-center mb-4 md:mb-0">
								<li className="ml-4">
									<Link
										to="https://www.linkedin.com/company/syncrobio"
										target="_blank"
										className="flex justify-center items-center hover:text-gray-500 transition duration-150 ease-in-out"
										aria-label="LinkedIn">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 50 50"
											width="50px"
											height="50px"
											fill="#0f2666">
											<path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z" />
										</svg>
									</Link>
								</li>
								<li className="ml-4">
									<Link
										to="https://x.com/syncrobio"
										target="_blank"
										className="flex justify-center items-center hover:text-gray-500 transition duration-150 ease-in-out"
										aria-label="X">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 50 50"
											width="50px"
											height="50px"
											fill="#0f2666">
											<path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
										</svg>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
					<div className="w-full  px-4 mx-auto text-center py-2">
						<div className="text-sm text-gray-200 font-semibold py-1">
							Copyright &copy; {new Date().getFullYear()}
							{"  "}
							<HashLink
								to="#"
								className=" hover:text-gray-900">
								SyncroBio Consulting
							</HashLink>
							. All rights reserved.
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
export default Footer;

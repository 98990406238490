import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
	return (
		<div
			className="my-4 py-4"
			id="portfolio">
			<h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
				Products
			</h2>
			<div className="flex justify-center">
				<div className="w-24 border-b-4 border-blue-900 mb-8"></div>
			</div>

			<div
				className="px-4"
				data-aos="fade-down"
				data-aos-delay="600">
				<div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
					<div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
						<div className="m-2 text-justify text-sm">
							<h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
								Integrated Health Management Systems
							</h4>
							<p className="text-md font-medium leading-5 h-auto md:h-48">
								Our Integrated Health Management Platform integrates patient
								data seamlessly, enhances clinical workflows, and streamlines
								administrative tasks to optimize healthcare delivery. This
								comprehensive solution includes modules for patient records,
								scheduling, billing, and reporting, ensuring efficient
								healthcare management.
							</p>
							<div className="flex justify-center my-4">
								<Link
									to="/get-demo"
									className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
									Schedule Demo
									<svg
										className="w-4 h-4 ml-1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clipRule="evenodd"></path>
									</svg>
								</Link>
							</div>
						</div>
					</div>

					<div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
						<div className="m-2 text-justify text-sm">
							<h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
								Telehealth and Virtual Care Platforms
							</h4>
							<p className="text-md font-medium leading-5 h-auto md:h-48">
								Our Telehealth and Virtual Care Solution facilitates remote
								consultations, secure patient monitoring, and telemedicine
								services. It supports real-time interactions between healthcare
								providers and patients, improving access to care while
								maintaining high standards of privacy and security.
							</p>
							<div className="flex justify-center my-4">
								<Link
									to="/get-demo"
									className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
									Schedule Demo
									<svg
										className="w-4 h-4 ml-1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clipRule="evenodd"></path>
									</svg>
								</Link>
							</div>
						</div>
					</div>

					<div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
						<div className="m-2 text-justify text-sm">
							<h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
								AI-Powered Clinical Decision Support Tools
							</h4>
							<p className="text-md font-medium leading-5 h-auto md:h-48">
								Our AI-Powered Clinical Decision Support System leverages
								artificial intelligence to assist healthcare professionals in
								making informed decisions. From diagnostics to treatment
								planning, predictive analytics, and patient management, this
								tool enhances accuracy, efficiency, and patient outcomes.
							</p>
							<div className="flex justify-center my-4">
								<Link
									to="/get-demo"
									className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
									Schedule Demo
									<svg
										className="w-4 h-4 ml-1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clipRule="evenodd"></path>
									</svg>
								</Link>
							</div>
						</div>
					</div>

					<div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
						<div className="m-2 text-justify text-sm">
							<h4 className="font-semibold my-4 text-lg md:text-2xl text-center mb-4 h-12">
								IoT-Based Health Monitoring Devices
							</h4>
							<p className="text-md font-medium leading-5 h-auto md:h-48">
								Our IoT-Based Health Monitoring Devices offer real-time data
								collection and analysis for proactive healthcare management.
								These innovative devices track vital signs, activity levels, and
								other health metrics, empowering patients and healthcare
								providers with actionable insights for personalized care and
								early intervention.
							</p>
							<div className="flex justify-center my-4">
								<Link
									to="/get-demo"
									className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
									Schedule Demo
									<svg
										className="w-4 h-4 ml-1"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor">
										<path
											fillRule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clipRule="evenodd"></path>
									</svg>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Portfolio;
